import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ExerciseApi } from 'fitify-types/src/types/coach-admin-api'
import { WithId } from 'fitify-types/src/types/common'
import {
  BlockedExercisesCollection,
  ExercisesDataCollection,
  FitifyExercisesDataCollection,
} from 'fitify-types/src/types/exercise-collection'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

import { SessionService } from '@/api/services/SessionService'
import { UserService } from '@/api/services/UserService'
import { AppDispatch, AppState } from '@/store/store'
import { CoachPlan } from '@/types/CoachPlan'
import { mergeExercisesState } from '@/utils/exercises'

export const fetchSessionHeartRateRecords = createAsyncThunk<
  HumanCoaching.FitifyHeartRateSample[],
  { userId: string; sessionId: string },
  {
    dispatch: AppDispatch
    state: AppState
  }
>(
  'activity/fetchSessionHeartRateRecords',
  async (data: { userId: string; sessionId: string }) => {
    return SessionService.getHeartRateRecords(data.userId, data.sessionId)
  }
)

export const createCustomExercise = createAsyncThunk(
  'exercise/createCustomExercise',
  async (payload: CoachPlan.CustomExercisePayload) => {
    return UserService.createCustomExercise({
      userId: payload.userId,
      customExercise: payload.customExercise,
    })
  }
)

export const deleteCustomExercise = createAsyncThunk(
  'exercise/deleteCustomExercise',
  async (data: { userId: string; exerciseId: string }) => {
    return UserService.deleteCustomExercise(data.userId, data.exerciseId)
  }
)

export const updateCustomExercise = createAsyncThunk(
  'exercise/updateCustomExercise',
  async (payload: CoachPlan.CustomExercisePayload) => {
    return UserService.updateCustomExercise({
      userId: payload.userId,
      customExercise: payload.customExercise,
    })
  }
)

export const fetchCustomExercises = createAsyncThunk(
  'exercise/fetchCustomExercises',
  async (data: { userId: string }) => {
    return UserService.getCustomExercises(data.userId)
  }
)

export const fetchBlockedExercises = createAsyncThunk(
  'exercise/fetchBlockedExercises',
  async (userId: string) => {
    return UserService.getBlockedExercises(userId)
  }
)

export const updateBlockedExercises = createAsyncThunk(
  'exercise/updateBlockedExercises',
  async ({
    userId,
    data,
  }: {
    userId: string
    data: ExerciseApi.UpdateBlockedExercises.Request
  }) => {
    return UserService.updateBlockedExercises(userId, data)
  }
)

export type ActivityBuilderGeneralState = {
  heartRateRecords: HumanCoaching.FitifyHeartRateSample[]
  exercisesDataCollection: ExercisesDataCollection
  customExercises: WithId<HumanCoaching.CustomExercise>[]
  blockedExercises: BlockedExercisesCollection
  dnd: {
    exercise: CoachPlan.SequenceExercise | null
  }
  isSubmitTriggered: boolean
}

const initialState: ActivityBuilderGeneralState = {
  heartRateRecords: [],
  exercisesDataCollection: {},
  customExercises: [],
  blockedExercises: {},
  dnd: {
    exercise: null,
  },
  isSubmitTriggered: false,
}

export const activityBuilderGeneralSlice = createSlice({
  name: 'activityBuilderGeneral',
  initialState,
  reducers: {
    setExercisesDataCollection: (
      state,
      { payload }: { payload: FitifyExercisesDataCollection }
    ) => {
      state.exercisesDataCollection = payload
    },
    setDragAndDropExercise: (
      state,
      { payload }: { payload: CoachPlan.SequenceExercise | null }
    ) => {
      state.dnd.exercise = payload
    },
    setSubmitTriggered: (state) => {
      state.isSubmitTriggered = true
    },
    resetActivityBuilderGeneral: (state) => {
      state.isSubmitTriggered = false
      state.heartRateRecords = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessionHeartRateRecords.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.heartRateRecords = action.payload.sort((a, b) => {
          return a.offset - b.offset
        })
      }
    })
    builder.addCase(fetchCustomExercises.fulfilled, (state, action) => {
      state.customExercises = action.payload

      state.exercisesDataCollection = mergeExercisesState(
        state.customExercises,
        state.exercisesDataCollection
      )
    })
    builder.addCase(createCustomExercise.fulfilled, (state, action) => {
      state.customExercises.push(action.payload)

      state.exercisesDataCollection = mergeExercisesState(
        state.customExercises,
        state.exercisesDataCollection
      )
    })
    builder.addCase(deleteCustomExercise.fulfilled, (state, action) => {
      for (let i = 0; i < state.customExercises.length; i++) {
        if (state.customExercises[i].id === action.meta.arg.exerciseId) {
          state.customExercises.splice(i, 1)
        }
      }
      state.exercisesDataCollection = mergeExercisesState(
        state.customExercises,
        state.exercisesDataCollection
      )
    })
    builder.addCase(updateCustomExercise.fulfilled, (state, action) => {
      for (let i = 0; i < state.customExercises.length; i++) {
        if (state.customExercises[i].id === action.payload.id) {
          state.customExercises[i] = action.payload
          break
        }
      }
      state.exercisesDataCollection = mergeExercisesState(
        state.customExercises,
        state.exercisesDataCollection
      )
    })
    builder.addCase(fetchBlockedExercises.fulfilled, (state, action) => {
      state.blockedExercises = action.payload
    })
    builder.addCase(updateBlockedExercises.fulfilled, (state, action) => {
      state.blockedExercises = action.payload
    })
  },
})

export const {
  resetActivityBuilderGeneral,
  setDragAndDropExercise,
  setExercisesDataCollection,
  setSubmitTriggered,
} = activityBuilderGeneralSlice.actions

export const activityBuilderGeneralSelector = (state: AppState) =>
  state.activityBuilderGeneral

export default activityBuilderGeneralSlice.reducer
