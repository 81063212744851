import React, { createContext, useState, Context, useContext } from 'react'

interface ExerciseFilterDataContextProps {
  filterQuery: string | undefined
  setFilterQuery: (filterQuery: string) => void
}

export const ExerciseFilterDataContext: Context<ExerciseFilterDataContextProps> =
  createContext<ExerciseFilterDataContextProps>({
    // eslint-disable-next-line no-empty-function
    setFilterQuery: () => {},
    filterQuery: '',
  })

export function useProvideExerciseFilterData() {
  const [filterQuery, setFilterQuery] = useState<string | undefined>(undefined)

  return {
    filterQuery,
    setFilterQuery,
  }
}

export function ExerciseFilterDataProvider({ children }: any) {
  const exerciseData = useProvideExerciseFilterData()
  return (
    <ExerciseFilterDataContext.Provider value={exerciseData}>
      {children}
    </ExerciseFilterDataContext.Provider>
  )
}

export const useExerciseFilterData = () => useContext(ExerciseFilterDataContext)
