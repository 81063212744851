import { type AxiosResponse } from 'axios'
import { Coach } from 'fitify-types/src/types/coach'
import {
  ActivityApi,
  ExerciseApi,
  FitnessDataApi,
  UserApi,
} from 'fitify-types/src/types/coach-admin-api'
import { WithId } from 'fitify-types/src/types/common'
import { ExerciseHistory } from 'fitify-types/src/types/exercise-history'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'
import { User } from 'fitify-types/src/types/user'

import { API_URL, apiClient } from '@/api'
import { ActivityDetail } from '@/store/activity/builder/builderSlice'
import { CoachPlan } from '@/types/CoachPlan'
import { Fitify } from '@/types/user'

export const UserService = {
  Me: async (): Promise<Record<string, unknown>> => {
    const response: AxiosResponse = await apiClient.get(`${API_URL}/auth/me`)
    return response.data
  },
  getAllUsers: async (): Promise<Record<string, unknown>> => {
    const response: AxiosResponse = await apiClient.get(`${API_URL}/users`)

    return response.data
  },
  getAllCoaches: async (): Promise<WithId<Coach>[]> => {
    const response: AxiosResponse = await apiClient.get(`${API_URL}/coaches`)

    return response.data
  },
  getDetails: async (id: string): Promise<Fitify.User> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${id}`
    )

    return response.data
  },
  getCoachPlanDays: async (
    id: string,
    fromDate: string,
    toDate: string
  ): Promise<WithId<HumanCoaching.Day>[]> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${id}/coach-plan-days?from=${fromDate}&to=${toDate}`
    )

    return response.data.data
  },
  getWeightRecords: async (
    id: string | string[]
  ): Promise<FitnessDataApi.WeightRecords.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${id}/weight-records`
    )

    return response.data.data
  },
  getVO2MaxRecords: async (
    id: string
  ): Promise<FitnessDataApi.VO2MaxRecords.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${id}/vo2max-records`
    )

    return response.data.data
  },
  getRegisteredTokens: async (
    userId: string
  ): Promise<User.RegistrationToken[]> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/registration_tokens`
    )

    return response.data.data
  },
  getByEmail: async (email: string): Promise<Fitify.User> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/email/${encodeURI(email)}`
    )

    return response.data
  },

  editProfile: async (
    id: string | string[],
    data: Record<string, unknown>
  ): Promise<Fitify.User> => {
    const response: AxiosResponse = await apiClient.post(
      `${API_URL}/users/${id}`,
      {
        data,
      }
    )

    return response.data
  },

  updateUserFields: async (
    id: string,
    data: UserApi.UpdateUserFields.Request
  ): Promise<Fitify.User> => {
    const response: AxiosResponse = await apiClient.put(
      `${API_URL}/users/${id}`,
      data
    )

    return response.data
  },

  getActivityDetails: async (
    id: string,
    coachPlanDayId: string,
    activityId: string,
    sessionId?: string
  ): Promise<ActivityDetail> => {
    let url = `${API_URL}/users/${id}/coach-plan-days/${coachPlanDayId}/activities/${activityId}`

    const params = new URLSearchParams()

    if (sessionId) {
      params.append('session_id', sessionId)
    }

    if (params.toString()) {
      url += `?${params.toString()}`
    }

    const response: AxiosResponse = await apiClient.get(url)
    return response.data.data
  },

  updateCoachPlanDay: async (
    id: string,
    date: string,
    data: { title?: string | null; voiceover?: string | null }
  ): Promise<void> => {
    await apiClient.put(`${API_URL}/users/${id}/coach-plan-days/${date}`, {
      data,
    })
  },

  updateActivity: async (
    coachPlanDayId: string,
    userId: string,
    data: Record<string, unknown>
  ): Promise<WithId<HumanCoaching.Activity>> => {
    const response: AxiosResponse = await apiClient.put(
      `${API_URL}/users/${userId}/coach-plan-days/${coachPlanDayId}/activities`,
      {
        data,
      }
    )
    return response.data.activity
  },
  createActivity: async (
    coachPlanDayId: string,
    userId: string,
    data: Record<string, unknown>
  ): Promise<WithId<HumanCoaching.Activity>> => {
    const response: AxiosResponse = await apiClient.post(
      `${API_URL}/users/${userId}/coach-plan-days/${coachPlanDayId}/activities`,
      {
        data,
      }
    )
    return response.data.activity
  },

  deleteActivities: async (
    userId: string,
    activities: {
      date: string
      id: string
    }[]
  ): Promise<void> => {
    await apiClient.del(`${API_URL}/users/${userId}/activities`, {
      activities,
    })
  },

  deleteDays: async (
    userId: string,
    data: ActivityApi.DeleteDays.Request
  ): Promise<void> => {
    await apiClient.del(`${API_URL}/users/${userId}/coach-plan-days`, {
      data,
    })
  },

  reorderActivities: async (
    id: string | string[],
    date: string | string[],
    activities: ActivityApi.ReorderActivities.Request
  ): Promise<void> => {
    await apiClient.put(
      `${API_URL}/users/${id}/coach-plan-days/${date}/activities/reorder`,
      {
        activities,
      }
    )
  },

  getActivitySummaries: async (
    id: string,
    fromDate: string,
    toDate: string
  ): Promise<FitnessDataApi.ActivitySummaries.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${id}/activity-summaries?from=${fromDate}&to=${toDate}`
    )
    return response.data.data
  },

  duplicateActivities: async (
    userId: string,
    data: ActivityApi.DuplicateActivities.Request
  ): Promise<WithId<HumanCoaching.Activity>[]> => {
    const response: AxiosResponse = await apiClient.post(
      `${API_URL}/users/${userId}/activities/duplicate`,
      {
        data,
      }
    )
    return response.data
  },

  moveActivities: async (
    userId: string,
    data: ActivityApi.DuplicateActivities.Request
  ): Promise<WithId<HumanCoaching.Activity>[]> => {
    const response: AxiosResponse = await apiClient.post(
      `${API_URL}/users/${userId}/activities/move`,
      {
        data,
      }
    )
    return response.data
  },

  duplicateDays: async (
    userId: string,
    data: ActivityApi.DuplicateDays.Request
  ): Promise<WithId<HumanCoaching.Day>[]> => {
    const response: AxiosResponse = await apiClient.post(
      `${API_URL}/users/${userId}/coach-plan-days/duplicate`,
      {
        data,
      }
    )
    return response.data.data
  },

  moveDays: async (
    userId: string,
    data: ActivityApi.DuplicateDays.Request
  ): Promise<WithId<HumanCoaching.Day>[]> => {
    const response: AxiosResponse = await apiClient.post(
      `${API_URL}/users/${userId}/coach-plan-days/move`,
      {
        data,
      }
    )
    return response.data.data
  },

  createCustomExercise: async (
    payload: CoachPlan.CustomExercisePayload
  ): Promise<WithId<HumanCoaching.CustomExercise>> => {
    const response: AxiosResponse = await apiClient.post(
      `${API_URL}/users/${payload.userId}/custom-exercises`,
      {
        data: payload.customExercise,
      }
    )
    return response.data.data
  },

  updateCustomExercise: async (
    payload: CoachPlan.CustomExercisePayload
  ): Promise<WithId<HumanCoaching.CustomExercise>> => {
    const response: AxiosResponse = await apiClient.put(
      `${API_URL}/users/${payload.userId}/custom-exercises/${payload.customExercise.id}`,
      {
        data: payload.customExercise,
      }
    )
    return response.data.data
  },

  deleteCustomExercise: async (
    userId: string,
    exerciseId: string
  ): Promise<[]> => {
    const response: AxiosResponse = await apiClient.del(
      `${API_URL}/users/${userId}/custom-exercises/${exerciseId}`
    )
    return response.data.data
  },

  getCustomExercises: async (
    userId: string
  ): Promise<WithId<HumanCoaching.CustomExercise>[]> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/custom-exercises`
    )
    return response.data.data
  },

  getUserAttribution: async (
    userId: string
  ): Promise<User.Attribution | null> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/attribution`
    )

    return response.data.data
  },

  getDayOverviewHealthData: async (
    userId: string,
    dayId: string
  ): Promise<FitnessDataApi.DayOverview.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/day-overview/${dayId}`
    )
    return response.data.data
  },

  getAppleHealthActivities: async (
    userId: string,
    fromDate: string,
    toDate: string
  ): Promise<WithId<HumanCoaching.NonFitifyActivity>[]> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/activities?from=${fromDate}&to=${toDate}`
    )
    return response.data.data
  },

  getExerciseHistoryStatistics: async (
    userId: string,
    exerciseCode: string,
    toDate: string,
    fromDate?: string
  ): Promise<ExerciseHistory.AllStatistics> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/exercises/${exerciseCode}/statistics?to=${toDate}${
        fromDate ? `&from=${fromDate}` : ''
      }`
    )

    return response.data.data
  },

  getExercisesUsageFrequency: async (
    userId: string,
    activityType: string,
    coachPlanDayId: string,
    position?: number | null
  ): Promise<ExerciseHistory.UsageFrequency> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/exercises-usage-frequency?coach_activity_type=${activityType}&coach_plan_day_id=${coachPlanDayId}${
        typeof position === 'number' ? `&position=${position}` : ''
      }`
    )

    return response.data.data
  },

  getExercisesDislikes: async (
    userId: string,
    activityType: string
  ): Promise<ExerciseHistory.Dislikes> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/exercises-dislikes?coach_activity_type=${activityType}`
    )

    return response.data.data
  },

  getExerciseHistory: async (
    userId: string,
    exerciseCode: string
  ): Promise<ExerciseHistory.History> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/exercises/${exerciseCode}/history`
    )
    return response.data.data
  },

  getUserPanelTags: async (
    userId: string
  ): Promise<UserApi.UserPanelTags.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/user-panel-tags`
    )
    return response.data.data
  },

  getAvailableData: async (
    userId: string
  ): Promise<FitnessDataApi.AvailableData.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/available-data`
    )
    return response.data.data
  },

  getTransactions: async (
    userId: string
  ): Promise<UserApi.Transactions.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/transactions`
    )
    return response.data.data
  },

  getBlockedExercises: async (
    userId: string
  ): Promise<ExerciseApi.GetBlockedExercises.Response> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/blocked-exercises`
    )
    return response.data.data
  },

  updateBlockedExercises: async (
    userId: string,
    data: ExerciseApi.UpdateBlockedExercises.Request
  ): Promise<ExerciseApi.UpdateBlockedExercises.Response> => {
    const response: AxiosResponse = await apiClient.put(
      `${API_URL}/users/${userId}/blocked-exercises`,
      {
        data,
      }
    )
    return response.data.data
  },
}
