import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FitnessDataApi, UserApi } from 'fitify-types/src/types/coach-admin-api'
import { User } from 'fitify-types/src/types/user'

import { UserService } from '@/api/services/UserService'
import { userInitialState } from '@/store/common/userInitialState'
import { Fitify } from '@/types/user'
import { mapDefaultValues } from '@/utils/user'
import { validateEmail } from '@/utils/validators'

import { AppDispatch, AppState } from '../store'

export interface UserState {
  user: Fitify.User
  weightRecords: FitnessDataApi.WeightRecords.Response | null
  attribution: User.Attribution | null
  search: any | null
  loading: boolean
  error: null | string
  userPanelTags: UserApi.UserPanelTags.Response | null
  registrationTokens: User.RegistrationToken[]
  availableData: FitnessDataApi.AvailableData.Response | null
  transactions: UserApi.Transactions.Response | null
  vo2MaxRecords: FitnessDataApi.VO2MaxRecords.Response | null
  isLoaded: boolean
}

const initialState: UserState = userInitialState

export const findUser = createAsyncThunk(
  'user/findUser',
  async (identifier: string) => {
    if (validateEmail(identifier)) {
      return UserService.getByEmail(identifier)
    } else {
      return UserService.getDetails(identifier)
    }
  }
)

export const fetchUserDetails = createAsyncThunk<
  Fitify.User,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchDetails', (id: string) => {
  return UserService.getDetails(id)
})

export const fetchUserWeightRecords = createAsyncThunk<
  FitnessDataApi.WeightRecords.Response,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchUserWeightRecords', async (id: string) => {
  return UserService.getWeightRecords(id)
})

export const fetchUserVO2MaxRecords = createAsyncThunk<
  FitnessDataApi.VO2MaxRecords.Response,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchUserVO2MaxRRecords', async (id: string) => {
  return UserService.getVO2MaxRecords(id)
})

export const fetchUserRegisteredTokens = createAsyncThunk<
  User.RegistrationToken[],
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchUserRegisteredTokens', async (userId: string) => {
  return UserService.getRegisteredTokens(userId)
})

export const editUserProfile = createAsyncThunk(
  'user/editProfile',
  async (userDTO: { id: string | string[]; data: Record<string, unknown> }) => {
    return UserService.editProfile(userDTO.id, userDTO.data)
  }
)

export const updateUserField = createAsyncThunk<
  Fitify.User,
  { userId: string; data: UserApi.UpdateUserFields.Request },
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/updateFields', async ({ userId, data }) => {
  return UserService.updateUserFields(userId, data)
})

export const fetchUserAttribution = createAsyncThunk<
  User.Attribution | null,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchAttribution', async (id: string) => {
  return UserService.getUserAttribution(id)
})

export const fetchUserPanelTags = createAsyncThunk<
  UserApi.UserPanelTags.Response,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchUserPanelTags', async (id: string) => {
  return UserService.getUserPanelTags(id)
})

export const fetchAvailableData = createAsyncThunk<
  FitnessDataApi.AvailableData.Response,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchAvailableData', async (id: string) => {
  return UserService.getAvailableData(id)
})

export const fetchTransactions = createAsyncThunk<
  UserApi.Transactions.Response,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchTransactions', async (id: string) => {
  return UserService.getTransactions(id)
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.search = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.user = mapDefaultValues(action.payload)
      state.loading = false
      state.isLoaded = true
    })
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
    builder.addCase(findUser.fulfilled, (state, action) => {
      state.search = action.payload
    })
    builder.addCase(findUser.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.search = {}
    })
    builder.addCase(fetchUserWeightRecords.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserWeightRecords.fulfilled, (state, action) => {
      state.weightRecords = action.payload
      state.loading = false
    })
    builder.addCase(fetchUserWeightRecords.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
    builder.addCase(fetchUserVO2MaxRecords.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserVO2MaxRecords.fulfilled, (state, action) => {
      state.vo2MaxRecords = action.payload
      state.loading = false
    })
    builder.addCase(fetchUserVO2MaxRecords.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
    builder.addCase(fetchUserRegisteredTokens.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserRegisteredTokens.fulfilled, (state, action) => {
      state.registrationTokens = action.payload
      state.loading = false
    })
    builder.addCase(fetchUserRegisteredTokens.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
    builder.addCase(editUserProfile.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(editUserProfile.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
    })
    builder.addCase(updateUserField.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(updateUserField.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
    })
    builder.addCase(fetchUserAttribution.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserAttribution.fulfilled, (state, action) => {
      state.attribution = action.payload
      state.loading = false
    })
    builder.addCase(fetchUserAttribution.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
    builder.addCase(fetchUserPanelTags.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserPanelTags.fulfilled, (state, action) => {
      state.userPanelTags = action.payload
      state.loading = false
    })
    builder.addCase(fetchUserPanelTags.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
    builder.addCase(fetchAvailableData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAvailableData.fulfilled, (state, action) => {
      state.availableData = action.payload
      state.loading = false
    })
    builder.addCase(fetchAvailableData.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
    builder.addCase(fetchTransactions.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload
      state.loading = false
    })
    builder.addCase(fetchTransactions.rejected, (state, action) => {
      state.error =
        action.error.message !== undefined ? action.error.message : null
      state.loading = false
    })
  },
})

export const { resetSearch } = userSlice.actions

export const userSelector = (state: AppState) => state.user
export default userSlice.reducer
