import { UnitSystem } from 'fitify-types/src/types/common'
import { kilogramsToPounds } from 'fitify-utils/src/converters'
import { type TFunction } from 'i18next'

export type MassUnits = MassMetricUnits | MassImperialUnits

export type MassMetricUnits = 'g' | 'kg'
export type MassImperialUnits = 'oz' | 'lb'
export type DistanceMetricUnits = 'km'

export type DistanceImperialUnits = 'mi'

export interface MassObject {
  value: number
  system: UnitSystem
}

export const MASS_KG_LBS_COEFFICIENT = 2.2046
export const MASS_ML_OZ_COEFFICIENT = 0.03381
export const DISTANCE_M_FEET_COEFFICIENT = 3.28084
export const DISTANCE_MILE_TO_M_COEFICIENT = 1609.344
export const YARD_COEFFICIENT = 1.09361
/**
 * Return converted weight from kilograms to pounds
 * @deprecated Use kilogramsToPounds instead of this
 * @param weight  The Weight in pounds
 * @param decimalPlaces  The decimal places
 * @returns The Weight converted from kilograms to pounds
 */
export const toPounds = (weight: number, decimalPlaces?: number): number => {
  return decimalPlaces
    ? Math.round(weight * MASS_KG_LBS_COEFFICIENT * 10 ** decimalPlaces) /
        10 ** decimalPlaces
    : Math.round(weight * MASS_KG_LBS_COEFFICIENT)
}
/**
 * Return converted volume from milliliters to ounces
 * @param volume  The volume in milliliters
 * @param decimalPlaces  The decimal places
 * @returns The volume converted from milliliters to ounces
 */
export const millilitersToOunces = (
  volume: number,
  decimalPlaces?: number
): number => {
  return decimalPlaces
    ? Math.round(volume * MASS_ML_OZ_COEFFICIENT * 10 ** decimalPlaces) /
        10 ** decimalPlaces
    : Math.round(volume * MASS_ML_OZ_COEFFICIENT)
}

export const toRawFeet = (value: number) => {
  return value * DISTANCE_M_FEET_COEFFICIENT
}

/**
 * Returns the value from inches to centimeters
 * @param value The value of height in inches
 * @param decimalPlaces  The decimal places
 */
export const inchesToCm = (value: number, decimalPlaces?: number): number => {
  return decimalPlaces
    ? (Math.round(value * 2.54) * 10 ** decimalPlaces) / 10 ** decimalPlaces
    : Math.round(value * 2.54)
}

/**
 * Returns the value from centimeters to inches
 * @param value The value of height in centimeters
 * @param decimalPlaces  The decimal places
 * @returns The value from centimeters to inches
 */
export const cmToInches = (value: number, decimalPlaces?: number): number => {
  return decimalPlaces
    ? (Math.round(value * 0.3937007874) * 10 ** decimalPlaces) /
        10 ** decimalPlaces
    : Math.round(value * 0.3937007874)
}

/**
 * Returns the height in feet and inches from cm
 */
export const toFeet = (
  value: number,
  unitSystem: UnitSystem,
  t: TFunction,
  isShortTranslation = false
) => {
  const inchesValue =
    unitSystem === UnitSystem.Imperial ? value : cmToInches(value)
  const feet = Math.floor(inchesValue / 12)
  const inches = Math.round(inchesValue % 12)

  return t(
    isShortTranslation
      ? 'profile_height_x_ft_y_in_short'
      : 'profile_height_x_ft_y_in',
    {
      0: feet,
      1: inches,
    }
  )
}

export const milesToMeters = (distance: number): number => {
  return distance * DISTANCE_MILE_TO_M_COEFICIENT
}

export const kmToMeters = (distance: number): number => {
  return distance * 1000
}

export const toTestId = (dataTestString: string) => {
  return dataTestString.replace(/ /g, '-').toLowerCase()
}

/**
 * Return converted weight from pounds to kilograms
 * @param weight  The Weight in pounds
 * @returns The Weight converted from pounds to kilograms
 */
export const toRawKgs = (weight: number): number => {
  return weight / MASS_KG_LBS_COEFFICIENT
}

export const metersToYards = (meters: number): number => {
  return meters * YARD_COEFFICIENT
}

/**
 * Returns rounded actual value to the nearest quarter
 */
export const roundActualValueToNearestQuarter = (value?: number) => {
  if (!value) {
    return 0
  }

  const roundedValue = Math.round(value * 4) / 4

  return Number.isInteger(roundedValue)
    ? roundedValue
    : parseFloat(roundedValue.toFixed(2))
}

/**
 * Return converted weight from pounds to kilograms
 * @deprecated Use toRawKgs instead of this
 * @param weight The Weight in pounds
 * @param decimalPlaces  The decimal places
 * @returns The Weight converted from pounds to kilograms
 */
export const toKgs = (weight: number, decimalPlaces?: number): number => {
  return decimalPlaces
    ? Math.round((weight / MASS_KG_LBS_COEFFICIENT) * 10 ** decimalPlaces) /
        10 ** decimalPlaces
    : Math.round(weight / MASS_KG_LBS_COEFFICIENT)
}

/**
 * Display UI weight formatted by user preference
 */
export const displayWeightWithUnits = (weight: MassObject, t: TFunction) => {
  return weight.system === 'imperial'
    ? t('profile_weight_x_lbs', { 0: weight.value })
    : t('profile_weight_x_kg', { 0: weight.value })
}

export const convertWeightUnit = (
  weight: number,
  formerUnit: UnitSystem,
  resultUnit: UnitSystem,
  decimalPlaces = 0
) => {
  const resultWeight =
    resultUnit === UnitSystem.Metric
      ? formerUnit === UnitSystem.Imperial
        ? toRawKgs(weight)
        : weight
      : formerUnit === UnitSystem.Metric
        ? kilogramsToPounds(weight)
        : weight
  return Math.round(resultWeight * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

export const convertHeightUnit = (
  height: number,
  formerUnit: UnitSystem,
  resultUnit: UnitSystem,
  decimalPlaces = 0
) => {
  const resultHeight =
    resultUnit === UnitSystem.Metric
      ? formerUnit === UnitSystem.Imperial
        ? inchesToCm(height)
        : height
      : formerUnit === UnitSystem.Metric
        ? cmToInches(height)
        : height
  return Math.round(resultHeight * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

/**
 * Display UI height formatted by user preference
 */
export const displayHeightWithUnits = (height: MassObject, t: TFunction) => {
  return height.system === 'imperial'
    ? toFeet(height.value, height.system, t)
    : t('profile_height_x_cm', { 0: height.value })
}

/**
 * Display UI height with converted units
 */
export const displayConvertedUnitHeight = (
  height: number,
  formerUnit: UnitSystem,
  resultUnit: UnitSystem,
  t: TFunction
) => {
  return resultUnit === UnitSystem.Imperial
    ? toFeet(height, formerUnit, t, true)
    : formerUnit === UnitSystem.Imperial
      ? t('profile_height_x_cm', { 0: inchesToCm(height, 1) })
      : t('profile_height_x_cm', { 0: height })
}

const replaceMatchedLinks = (
  description: string,
  regex: RegExp,
  hasPrefix: boolean
) => {
  return (description.match(regex) ?? []).reduce<string>((result, match) => {
    const url = match.trim()
    return result.replace(
      url,
      `<a href="${
        hasPrefix ? '' : '//'
      }${url}" target="_blank" style="text-decoration: underline; overflow-wrap: break-word" rel="noopener noreferrer">${url}</a>`
    )
  }, description)
}

export const convertUrlsToLinks = (description: string): string => {
  //regex patterns from https://uibakery.io/regex-library/url but optimized (remove starting and ending constraints)
  const urlWithPrefixRegex =
    /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*/gi
  const urlWithoutPrefixRegex =
    /(\s)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*|^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*/gi

  description = replaceMatchedLinks(description, urlWithPrefixRegex, true)
  description = replaceMatchedLinks(description, urlWithoutPrefixRegex, false)
  return description
}

export const getTotalDurationFromMMSS = (mmss: string) => {
  const [minutes, seconds] = mmss.split(':')
  return Number(minutes) * 60 + Number(seconds)
}

export const getMMSSFromSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')
  const remainingSeconds = (seconds % 60).toString().padStart(2, '0')
  return `${minutes}:${remainingSeconds}`
}

// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
/**
 * Returns formatted number i.e. 25490 to 25.5k
 * @param num The number we want to format
 * @param digits The precision value
 * @returns Formatted number
 */
export const numberFormatter = (num: number, digits = 1): string => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const regexPattern = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value
    })

  if (item) {
    const numFormattedToDigits = (num / item.value).toFixed(digits)
    const numWithoutTrailingZeros = numFormattedToDigits.replace(
      regexPattern,
      '$1'
    )
    return `${numWithoutTrailingZeros}${item.symbol}`
  }
  return '0'
}
