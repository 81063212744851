import { UnitSystem } from 'fitify-types/src/types/common'
import {
  ExercisesDataCollection,
  FitifyExerciseData,
} from 'fitify-types/src/types/exercise-collection'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

import { CoachPlan } from '@/types/CoachPlan'
import {
  CHANGE_SIDES_DURATION,
  CUSTOM_EXERCISE_REPS_PER_DURATION,
} from '@/utils/exercises'

import { MassObject, toRawKgs } from './converters'

export const getEstimatedCalories = (
  weight: MassObject,
  duration: number
): number => {
  const calculatedWeight =
    weight.system === UnitSystem.Imperial
      ? toRawKgs(weight.value)
      : weight.value
  return Math.round(calculatedWeight * 0.14 * (duration / 60))
}

const getCustomExerciseInstanceDuration = (
  exercise: CoachPlan.SequenceExercise,
  instance: CoachPlan.SequenceExerciseInstance
) => {
  let exerciseInstanceDuration = 0

  if (exercise.custom_exercise!.change_sides) {
    exerciseInstanceDuration += CHANGE_SIDES_DURATION
  }

  const exerciseReps = exercise.custom_exercise!.reps
  if (
    (exercise.reps_based ||
      exercise.volume_type === HumanCoaching.VolumeType.Reps) &&
    exerciseReps
  ) {
    exerciseInstanceDuration +=
      instance.prep_duration +
      instance.reps * (CUSTOM_EXERCISE_REPS_PER_DURATION / exerciseReps)
  } else {
    if (exercise.volume_type === HumanCoaching.VolumeType.Distance) {
      exerciseInstanceDuration += instance.prep_duration
    } else {
      exerciseInstanceDuration += instance.prep_duration + instance.duration
    }
  }

  return exerciseInstanceDuration
}

const getExerciseInstanceDuration = (
  exercise: CoachPlan.SequenceExercise,
  instance: CoachPlan.SequenceExerciseInstance,
  exercisesData: ExercisesDataCollection
) => {
  let exerciseInstanceDuration = 0

  const exerciseData = exercisesData[exercise.code] as FitifyExerciseData

  // When calculating the workout duration, CHANGE_SIDES_DURATION should be added for each exercise with change_sides = true.
  if (exerciseData.change_sides) {
    exerciseInstanceDuration += CHANGE_SIDES_DURATION
  }

  const exerciseReps = exerciseData.reps
  const exerciseDuration = exerciseData.duration

  if (
    (exercise.reps_based ||
      exercise.volume_type === CoachPlan.VolumeType.Reps) &&
    exerciseReps
  ) {
    exerciseInstanceDuration +=
      instance.prep_duration + instance.reps * (exerciseDuration / exerciseReps)
  } else {
    exerciseInstanceDuration += instance.prep_duration + instance.duration
  }

  return exerciseInstanceDuration
}

export const getSequenceEstimatedDuration = (
  exercises: CoachPlan.SequenceExercise[],
  exercisesData: ExercisesDataCollection
) => {
  return exercises.reduce((durationAcc, exercise) => {
    const instancesDuration = exercise.instances.reduce(
      (instancesDurationAcc, instance) => {
        if (exercise.custom_exercise) {
          const exerciseInstanceDuration = getCustomExerciseInstanceDuration(
            exercise,
            instance
          )
          return instancesDurationAcc + exerciseInstanceDuration
        } else {
          const exerciseInstanceDuration = getExerciseInstanceDuration(
            exercise,
            instance,
            exercisesData
          )

          return instancesDurationAcc + exerciseInstanceDuration
        }
      },
      0
    )

    let distanceBasedDuration = 0
    if (exercise.volume_type === 'distance') {
      distanceBasedDuration = exercise?.duration ? Number(exercise.duration) : 0
    }

    return Math.round(durationAcc + instancesDuration) + distanceBasedDuration
  }, 0)
}

export const getSequenceActualDuration = (
  exercises: CoachPlan.SequenceExercise[]
) => {
  return exercises.reduce((durationAcc, exercise) => {
    const instancesDuration = exercise.instances.reduce(
      (instancesDurationAcc, instance) => {
        const exerciseInstanceDuration =
          (instance?.actual_prep_duration ?? 0) +
          (instance?.actual_duration ?? 0)

        return instancesDurationAcc + exerciseInstanceDuration
      },
      0
    )
    return durationAcc + instancesDuration
  }, 0)
}
