import { WithId, XOR } from 'fitify-types/src/types/common'
import {
  CustomExerciseData,
  ExercisesData,
  ExercisesDataCollection,
} from 'fitify-types/src/types/exercise-collection'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'
import {
  getPrimaryEffortType,
  getPrimaryVolumeType,
} from 'fitify-utils/src/exercises'
import { compareArrays } from 'fitify-utils/src/properties'
import { v4 as uuidv4 } from 'uuid'

import { CoachPlan } from '@/types/CoachPlan'

import {
  DEFAULT_PREP_DURATION,
  DEFAULT_PREP_DURATION_NON_FIRST_SEQUENCE,
} from './validators'

export const CUSTOM_EXERCISE_DEFAULT_DURATION = 0
export const CUSTOM_EXERCISE_DEFAULT_REPS = 0
export const CHANGE_SIDES_DURATION = 5
export const CUSTOM_EXERCISE_REPS_PER_DURATION = 30

export const SUFFIX_DURATION = 's'
export const SUFFIX_REPS = '×'

/**
 * @param customExercises The custom exercises collection
 * @param exercisesDataCollection Key-value paired fitify exercises
 * @returns ExercisesDataCollection It returns the normalized format for exercises [code/id]:ExerciseData
 */
export const mergeExercisesState = (
  customExercises: WithId<HumanCoaching.CustomExercise>[],
  exercisesDataCollection: ExercisesDataCollection
): ExercisesDataCollection => {
  return customExercises.reduce(
    (exercises, { id, ...restCustomExercise }) => {
      exercises[id] = {
        code: id,
        ...restCustomExercise,
      }
      return exercises
    },
    { ...exercisesDataCollection }
  )
}

/**
 * Checks if an exercise is a custom exercise.
 *
 * @param {ExercisesData} exerciseData - The exercise data to check.
 * @returns {boolean} `true` if the exercise is a custom exercise; `false` otherwise.
 */
export const isCustomExercise = (exerciseData: ExercisesData): boolean => {
  return exerciseData.created ? true : false
}

/**
 * Calculates current maxRound attribute for a sequence
 * @param sequence Sequence to generate the maxRounds for
 * @returns maxRound Sequence attribute
 */
export const getSequenceMaxRound = (sequence: CoachPlan.Sequence) => {
  let res: number = sequence.maxRound
  if (!sequence.exercises.length && sequence.initialExerciseInstances) {
    res = sequence.initialExerciseInstances - 1
  }
  return res
}

/**
 * Calculates the default duration for an exercise instance, based on the exercise data.
 * @param {ExercisesData} exerciseData - The exercise data to use.
 * @returns {number} The default duration for an exercise instance.
 */
export const getExerciseInstanceDefaultDuration = (
  exerciseData: ExercisesData
): number => {
  if (!isCustomExercise(exerciseData)) {
    if (exerciseData.duration) {
      if (exerciseData.change_sides) {
        // The total duration for exercises with change sides needs to be divisible by 2
        return Math.round(exerciseData.duration / 2) * 2
      } else {
        return exerciseData.duration
      }
    }
  }
  return CUSTOM_EXERCISE_DEFAULT_DURATION
}

/**
 * Calculates the default reps for an exercise instance, based on the exercise data.
 * @param {ExercisesData} exerciseData - The exercise data to use.
 * @returns {number} The default reps for an exercise instance.
 */
export const getExerciseInstanceDefaultReps = (
  exerciseData: ExercisesData
): number => {
  if (!isCustomExercise(exerciseData)) {
    const reps = exerciseData.reps
    if (reps) {
      if (!exerciseData.reps_double && exerciseData.change_sides) {
        return reps * 2
      }

      if (exerciseData.reps_double) {
        if (reps % 2 === 1) {
          return Math.round(reps + 1 / 2) * 2
        } else {
          return Math.round(reps / 2) * 2
        }
      } else {
        return reps
      }
    }
  }
  return CUSTOM_EXERCISE_DEFAULT_REPS
}

/**
 * Generates exercise instances based on the exercise data.
 */
export const generateExerciseInstances = (
  exerciseData: ExercisesData,
  sequences: CoachPlan.ActivityBuilderSequences,
  sequenceId: string,
  newIndex: number,
  firstSequenceId: string
): CoachPlan.ExerciseInstance[] => {
  const generatedInstances: CoachPlan.ExerciseInstance[] = []
  let numberOfInstances = 1
  const currentSequence = sequences[sequenceId]
  const sequenceInitialExerciseInstances = currentSequence.maxRound + 1

  const getMaxInstances = () => {
    const lengthArray = currentSequence.exercises.map(
      (exercise) => exercise.instances.length
    )

    return Math.max(...lengthArray) !== -Infinity ? Math.max(...lengthArray) : 0
  }

  if (currentSequence && sequenceInitialExerciseInstances !== undefined) {
    numberOfInstances =
      sequenceInitialExerciseInstances > getMaxInstances()
        ? sequenceInitialExerciseInstances
        : getMaxInstances()
  }

  const isDefaultPrepExtended =
    (newIndex === 0 || (newIndex === 1 && !currentSequence.exercises.length)) &&
    sequenceId !== firstSequenceId

  for (let i = 0; i < numberOfInstances; i++) {
    generatedInstances.push({
      id: uuidv4(),
      duration: getExerciseInstanceDefaultDuration(exerciseData),
      prep_duration:
        isDefaultPrepExtended && i === 0
          ? DEFAULT_PREP_DURATION_NON_FIRST_SEQUENCE
          : DEFAULT_PREP_DURATION,
      round: i,
      reps: getExerciseInstanceDefaultReps(exerciseData),
      weight: 0,
    } as CoachPlan.ExerciseInstance)
  }

  return generatedInstances
}

/**
 * Returns boolean indicating if item is custom exercise
 * @param item Exercise to make decision about
 * @returns True if item is custom exercise
 */
export const isCustomExerciseGuard = (
  item: XOR<ExercisesData, WithId<HumanCoaching.CustomExercise>>
): item is WithId<HumanCoaching.CustomExercise> => {
  return typeof item.created !== 'undefined'
}

/**
 * Creates blank instance of exercise
 * @param exerciseCode Code / ID (custom exercise) of the exercise
 * @param exercisesDataCollection Details on the exercise
 * @param sequences Current sequences present in the workout builder
 * @param sequenceId Placement of the exercise
 * @param firstSequenceId ID of the first sequence in workout builder
 * @returns Instance of exercise to be added to any sequence
 */
export const getBlankExerciseInstance = (
  exerciseCode: string,
  exercisesDataCollection: ExercisesDataCollection,
  sequences: Record<string, CoachPlan.Sequence>,
  sequenceId: string,
  newIndex: number,
  firstSequenceId: string
): CoachPlan.SequenceExercise => {
  const exerciseDetails = exercisesDataCollection[exerciseCode]
  const isCustomExercise = isCustomExerciseGuard(exerciseDetails)

  const customExercise: WithId<HumanCoaching.CustomExercise> | undefined =
    isCustomExercise
      ? {
          ...(exerciseDetails as CustomExerciseData),
          id: exerciseDetails.code,
        }
      : undefined

  const newItem: CoachPlan.SequenceExercise = {
    id: uuidv4(),
    code: exerciseCode,
    reps_based: customExercise
      ? Boolean(customExercise.reps_supported)
      : Boolean(exerciseDetails.reps_preferred),
    instances: [
      ...generateExerciseInstances(
        exerciseDetails,
        sequences,
        sequenceId,
        newIndex,
        firstSequenceId
      ),
    ],
    custom_exercise: customExercise,
    effort_type: getPrimaryEffortType(exerciseDetails),
    volume_type: getPrimaryVolumeType(exerciseDetails),
  }

  return newItem
}

/**
 * Compares sequences and their exercises position. It returns true if the sequences are equal.
 */
export const compareExercisesPositionInSequences = (
  firstSequences: Record<string, CoachPlan.Sequence>,
  secondSequences: Record<string, CoachPlan.Sequence>
): boolean => {
  const firstSequencesKeys = Object.keys(firstSequences)
  const secondSequencesKeys = Object.keys(secondSequences)
  const hasEqualSequences =
    firstSequencesKeys.length === secondSequencesKeys.length &&
    firstSequencesKeys.every((key) => secondSequencesKeys.includes(key))

  if (!hasEqualSequences) {
    return false
  }

  return firstSequencesKeys.every((key) => {
    const firstSequence = firstSequences[key]
    const secondSequence = secondSequences[key]

    if (firstSequence.exercises.length !== secondSequence.exercises.length) {
      return false
    }

    const areExercisesPositionSame = compareArrays(
      firstSequence.exercises,
      secondSequence.exercises,
      (first, second) => {
        return first.id === second.id
      }
    )
    return areExercisesPositionSame
  })
}
